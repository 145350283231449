import { useMutation } from "@tanstack/react-query";
import { message } from "antd";

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserCreateData from "../../components/user/user";
import { addSubs, addUser } from "../../utils/api";
import SubscriptionCreateData from "../../components/subscription";

const AddSubscription = () => {
  const navigate = useNavigate();
  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await addSubs(values),
    onSuccess: () => {
      message.success(" created successfully");
      navigate("/subscription");
    },
  });
  const params = useParams();

  return <SubscriptionCreateData mutate={mutate} isPending={isPending} />;
};

export default AddSubscription;
