import { useMutation } from "@tanstack/react-query";
import { message } from "antd";

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserCreateData from "../../components/user/user";
import { addUser } from "../../utils/api";

const AddUser = ({ vendor }) => {
  const navigate = useNavigate();
  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await addUser(values),
    onSuccess: () => {
      message.success(" created successfully");
      navigate(vendor ? "/vendors" : "/users");
    },
  });
  const params = useParams();

  return (
    <UserCreateData mutate={mutate} isPending={isPending} vendor={vendor} />
  );
};

export default AddUser;
