import { ConfigProvider } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Layouts from "./components/common/Layout";
import Dashboard from "./pages/Dashboard";

import React from "react";
import Admin from "./pages/Admin";
import AddCategory from "./pages/Categories/Add";
import AllCaegories from "./pages/Categories/All";
import UpdateCategory from "./pages/Categories/Update";
import AddSubCategory from "./pages/Categories/sub-categoris/Add";
import AllSubCategories from "./pages/Categories/sub-categoris/All";
import UpdateSubCategory from "./pages/Categories/sub-categoris/Update";
import Collaboration from "./pages/Collaboration";
import LoginForm from "./pages/Login";
import Notification from "./pages/Notifications";
import Posts from "./pages/Posts";
import Transactions from "./pages/Transactions";

import KYC from "./pages/Kyc";
import SystemValues from "./pages/SystemValues";
import AddAdvert from "./pages/advert/Add";
import AllAdvert from "./pages/advert/AllAdvert";
import CollaborationAdvert from "./pages/advert/Collaboration";
import Leaderboard from "./pages/advert/Leaderboard";
import UpdateAdvert from "./pages/advert/Update";
import AddSubscription from "./pages/subscription/Add";
import Subscription from "./pages/subscription/All";
import UpdateSubscription from "./pages/subscription/Update";
import AddUser from "./pages/user/Add";
import AllUser from "./pages/user/AllUser";
import UserCollaboration from "./pages/user/Collaboration";
import UserNotifications from "./pages/user/Notifications";
import SubscriptionHistory from "./pages/user/SubscriptionHistory";
import TalentSeekerPurchase from "./pages/user/TalentSeekerPurchase";
import UpdateUser from "./pages/user/Update";
import Wallet from "./pages/user/Wallet";
import { useAuth } from "./utils/store";

function App() {
  const user = useAuth((state) => state.user);
  const router = useNavigate();
  React.useEffect(() => {
    if (!user) {
      router("/login");
    }
  }, [user, router]);
  return (
    <ConfigProvider>
      <Routes>
        <Route
          path="/"
          element={
            <Layouts>
              <Dashboard />
            </Layouts>
          }
        />
        {/* categories */}
        <Route
          path="/categories"
          element={
            <Layouts>
              <AllCaegories />
            </Layouts>
          }
        />
        <Route
          path="/categories/add"
          element={
            <Layouts>
              <AddCategory />
            </Layouts>
          }
        />
        <Route
          path="/categories/update/:id"
          element={
            <Layouts>
              <UpdateCategory />
            </Layouts>
          }
        />
        {/* subcategories */}
        <Route
          path="/subcategories/:cat"
          element={
            <Layouts>
              <AllSubCategories />
            </Layouts>
          }
        />
        <Route
          path="/subcategories/:cat/add"
          element={
            <Layouts>
              <AddSubCategory />
            </Layouts>
          }
        />
        <Route
          path="/subcategories/:cat/update/:id"
          element={
            <Layouts>
              <UpdateSubCategory />
            </Layouts>
          }
        />
        {/* user */}
        <Route
          path="/vendors"
          element={
            <Layouts>
              <AllUser vendor />
            </Layouts>
          }
        />
        <Route
          path="/company"
          element={
            <Layouts>
              <AllUser vendor company />
            </Layouts>
          }
        />
        <Route
          path="/vendors/add"
          element={
            <Layouts>
              <AddUser vendor />
            </Layouts>
          }
        />
        <Route
          path="/vendors/update/:id"
          element={
            <Layouts>
              <UpdateUser vendor />
            </Layouts>
          }
        />
        <Route
          path="/users"
          element={
            <Layouts>
              <AllUser />
            </Layouts>
          }
        />
        <Route
          path="/users/add"
          element={
            <Layouts>
              <AddUser />
            </Layouts>
          }
        />
        <Route
          path="/users/update/:id"
          element={
            <Layouts>
              <UpdateUser />
            </Layouts>
          }
        />
        {/* subscriotpion */}
        <Route
          path="/subscription"
          element={
            <Layouts>
              <Subscription />
            </Layouts>
          }
        />
        <Route
          path="/subscription/add"
          element={
            <Layouts>
              <AddSubscription />
            </Layouts>
          }
        />
        <Route
          path="/subscription/update/:id"
          element={
            <Layouts>
              <UpdateSubscription />
            </Layouts>
          }
        />
        <Route
          path="/notifications"
          element={
            <Layouts>
              <Notification />
            </Layouts>
          }
        />
        <Route
          path="/transactions"
          element={
            <Layouts>
              <Transactions />
            </Layouts>
          }
        />
        <Route
          path="/advert"
          element={
            <Layouts>
              <AllAdvert />
            </Layouts>
          }
        />
        <Route
          path="/advert/add"
          element={
            <Layouts>
              <AddAdvert />
            </Layouts>
          }
        />{" "}
        <Route
          path="/advert/update/:id"
          element={
            <Layouts>
              <UpdateAdvert />
            </Layouts>
          }
        />
        <Route
          path="/collaboration"
          element={
            <Layouts>
              <Collaboration />
            </Layouts>
          }
        />
        <Route
          path="/kyc"
          element={
            <Layouts>
              <KYC />
            </Layouts>
          }
        />
        <Route
          path="/collaboration-approved"
          element={
            <Layouts>
              <Collaboration type="Approved" />
            </Layouts>
          }
        />
        <Route
          path="/collaboration-rejected"
          element={
            <Layouts>
              <Collaboration type="Rejected" />
            </Layouts>
          }
        />
        <Route
          path="/system-values"
          element={
            <Layouts>
              <SystemValues />
            </Layouts>
          }
        />
        <Route
          path="/posts"
          element={
            <Layouts>
              <Posts />
            </Layouts>
          }
        />
        <Route
          path="/admins"
          element={
            <Layouts>
              <Admin />
            </Layouts>
          }
        />
        <Route
          path="/users/posts/:id"
          element={
            <Layouts>
              <Posts />
            </Layouts>
          }
        />
        <Route
          path="/users/collaboration/:id"
          element={
            <Layouts>
              <UserCollaboration />
            </Layouts>
          }
        />
        <Route
          path="/users/wallet/:id"
          element={
            <Layouts>
              <Wallet />
            </Layouts>
          }
        />
        <Route
          path="/users/notifications/:id"
          element={
            <Layouts>
              <UserNotifications />
            </Layouts>
          }
        />
        <Route
          path="/users/subscription/:id"
          element={
            <Layouts>
              <SubscriptionHistory />
            </Layouts>
          }
        />
        <Route
          path="/users/purchase/:id"
          element={
            <Layouts>
              <TalentSeekerPurchase />
            </Layouts>
          }
        />
        <Route
          path="/advert/collaboration/:id"
          element={
            <Layouts>
              <CollaborationAdvert />
            </Layouts>
          }
        />
        <Route
          path="/advert/leaderboard/:id"
          element={
            <Layouts>
              <Leaderboard />
            </Layouts>
          }
        />
        <Route path="/login" element={<LoginForm />} />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
