import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { deleteCategories, getAllCategories } from "../../utils/api";
import {
  Button,
  Image,
  Popconfirm,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { exportToExcel, JsonToExcel } from "react-json-to-excel";
import { useAuth } from "../../utils/store";
import Access from "../../components/Access";

const AllCaegories = () => {
  const user = useAuth((state) => state.user);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["categories"],
    queryFn: getAllCategories,
    enabled: !!user?.viewCategory,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await deleteCategories(values),
    onSuccess: () => {
      message.success(" deleted successfully");
      refetch();
    },
  });
  console.log(data);
  return (
    <Access granted={user?.viewCategory}>
      <div>
        <Space
          size={"large"}
          direction="horizontal"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Typography.Title>Categories</Typography.Title>
          <Space size="middle" direction="horizontal">
            {user?.downloadCategory && (
              <Button
                type="primary"
                onClick={() => {
                  exportToExcel(
                    data.map((data) => ({
                      id: data._id,
                      name: data.name,
                      image: data.image,
                      slug: data.slug,
                    })),
                    `categories${new Date().getTime()}`
                  );
                }}
              >
                Download
              </Button>
            )}

            <Link to="/categories/add">
              <Button type="primary">Add </Button>
            </Link>
          </Space>
        </Space>
        <Table
          dataSource={data}
          loading={isLoading}
          columns={[
            {
              title: "Name",
              dataIndex: "name",
            },
            {
              title: "Sub Categories",
              dataIndex: "subCategories",
              render: (_, record) => (
                <Link to={`/subcategories/${record?._id}`}>Sub Category</Link>
              ),
            },
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              render: (text, record) => (
                <Space size="middle">
                  <Link to={`/categories/update/${record?._id}`}>Edit</Link>

                  {user?.deleteCategory && (
                    <Popconfirm
                      title="Delete"
                      description="Are you sure to delete this ?"
                      onConfirm={() => {
                        mutate(record._id);
                      }}
                      onCancel={() => {
                        console.log("canceled");
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button danger>Delete</Button>
                    </Popconfirm>
                  )}
                </Space>
              ),
            },
          ]}
        />
      </div>
    </Access>
  );
};

export default AllCaegories;
