import { useMutation, useQuery } from "@tanstack/react-query";
import { message } from "antd";

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Advert from "../../components/Advert";
import { getAdvertById, updateAdvert } from "../../utils/api";
import { useAuth } from "../../utils/store";
import Access from "../../components/Access";

const UpdateAdvert = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await updateAdvert(params.id, values),
    onSuccess: () => {
      message.success("Successfully");
      navigate("/advert");
    },
  });
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["advert", params.id],
    queryFn: async () => await getAdvertById(params.id),
  });
  console.log(data);
  const user = useAuth((state) => state.user);
  return (
    <Access granted={user?.editAdvertisements}>
      <Advert mutate={mutate} isPending={isPending} update={data} />
    </Access>
  );
};

export default UpdateAdvert;
