import { faker } from "@faker-js/faker";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Image,
  Modal,
  Popconfirm,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { deleteAdvert, getAllAdvert } from "../../utils/api";
import { useAuth } from "../../utils/store";
import Access from "../../components/Access";

const AllAdvert = () => {
  const user = useAuth((state) => state.user);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["ads"],
    queryFn: () => getAllAdvert(),
    enabled: !!user?.viewAdvertisements,
  });
  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await deleteAdvert(values),
    onSuccess: () => {
      message.success(" deleted successfully");
      refetch();
    },
  });
  console.log(data, isLoading);

  return (
    <Access granted={user?.viewAdvertisements}>
      <div>
        <Space
          size={"large"}
          direction="horizontal"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Typography.Title>Advertisement</Typography.Title>
          <Link to={"/advert/add"}>
            <Button type="primary">Add </Button>
          </Link>
        </Space>
        <Table
          dataSource={data}
          columns={[
            {
              title: "Name",
              dataIndex: "name",
            },

            {
              title: "Link",
              dataIndex: ["link"],
              render: (link, record) =>
                // .mp3, .wav, .flac, .ogg
                record?.linkType === "Video" ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      const isAudio =
                        link?.includes("mp3") ||
                        link?.includes("wav") ||
                        link?.includes("flac") ||
                        link?.includes("ogg");
                      Modal.info({
                        title: "Player",
                        width: 800,

                        content: isAudio ? (
                          <audio
                            controls
                            style={{
                              width: "100%",
                              maxHeight: "60dvh",
                            }}
                          >
                            <source src={link} type="audio/mpeg" />
                          </audio>
                        ) : (
                          <video
                            controls
                            style={{
                              width: "100%",
                              maxHeight: "60dvh",
                            }}
                          >
                            <source src={link} type="video/mp4" />
                          </video>
                        ),
                      });
                    }}
                  >
                    {link?.includes("mp3") ||
                    link?.includes("wav") ||
                    link?.includes("flac") ||
                    link?.includes("ogg")
                      ? "Play Audio"
                      : "Watch Video"}
                  </Button>
                ) : (
                  <Image src={link} alt="player" width={100} height={100} />
                ),
            },
            {
              title: "Views",
              dataIndex: "views",
            },
            {
              title: "Watch Time",
              dataIndex: "watchTime",
            },
            {
              title: "Ad",
              dataIndex: "image",
              render: (text) => <Image src={text} width={100} />,
            },
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              render: (text, record) => (
                <Space
                  size="middle"
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  {record?.isCollaboration && (
                    <>
                      <Link to={`/advert/leaderboard/${record._id}`}>
                        <Button style={{ width: "100%" }} type="primary">
                          Leaderboard
                        </Button>
                      </Link>

                      <Link to={`/advert/collaboration/${record._id}`}>
                        <Button style={{ width: "100%" }} type="primary">
                          Collaboration
                        </Button>
                      </Link>
                    </>
                  )}

                  <Link to={`/advert/update/${record?._id}`}>
                    <Button
                      style={{
                        width: "100%",
                      }}
                    >
                      Edit
                    </Button>
                  </Link>

                  {user?.deleteAdvertisements && (
                    <Popconfirm
                      title="Delete"
                      description="Are you sure to delete this ?"
                      onConfirm={() => {
                        mutate(record._id);
                      }}
                      onCancel={() => {
                        console.log("canceled");
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        style={{
                          width: "100%",
                        }}
                        danger
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  )}
                </Space>
              ),
            },
          ]}
        />
      </div>
    </Access>
  );
};

export default AllAdvert;

const Posts = ({ id }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button
        style={{
          width: "100%",
        }}
        onClick={() => setVisible(true)}
        type="primary"
      >
        Collaboration
      </Button>
      <Modal
        footer={null}
        open={visible}
        onCancel={() => setVisible(false)}
        title="Collaboration"
      >
        <Space
          direction="vertical"
          size="large"
          style={{
            width: "100%",
          }}
        >
          {new Array(5)
            .fill({
              likes: faker.number.int({
                min: 0,
                max: 1000,
              }),
              comments: faker.number.int({
                min: 0,
                max: 1000,
              }),
              image: faker.image.urlPicsumPhotos({
                width: 600,
                height: 600,
              }),
            })
            .map((item, index) => (
              <Space
                key={index}
                direction="vertical"
                style={{
                  width: "100%",
                }}
              >
                <Image
                  src={item.image}
                  width={"100%"}
                  style={{
                    width: "100%",
                    aspectRatio: "16/9",
                  }}
                  preview={false}
                />
                <Space align="center">
                  <Typography.Text
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    <Image preview={false} width={20} src="/thump.png" />{" "}
                    {item.likes}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    <Image preview={false} width={20} src="/eye.png" />{" "}
                    {item.comments}
                  </Typography.Text>
                </Space>
              </Space>
            ))}
        </Space>
      </Modal>
    </>
  );
};
