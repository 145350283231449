import { useMutation, useQuery } from "@tanstack/react-query";
import { message } from "antd";

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserCreateData from "../../components/user/user";
import { getUserById, updateCategory, updateUser } from "../../utils/api";
import { useAuth } from "../../utils/store";

const UpdateUser = ({ vendor }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await updateUser(params.id, values),
    onSuccess: () => {
      message.success("Successfully");
      navigate(vendor ? `/vendors` : `/users`);
    },
  });
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["user", params.id],
    queryFn: async () => await getUserById(params.id),
  });
  const user = useAuth((state) => state.user);
  console.log(data);
  return (
    <UserCreateData
      mutate={mutate}
      isPending={isPending}
      update={data}
      vendor={vendor}
    />
  );
};

export default UpdateUser;
