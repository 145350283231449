import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Switch,
  Table,
  Typography,
  message,
} from "antd";
import React, { useState } from "react";
import { addToWallet, getUserById, getUserWallet } from "../../utils/api";
import { useParams } from "react-router-dom";
import Access from "../../components/Access";
import { useAuth } from "../../utils/store";

const Wallet = () => {
  const id = useParams().id;

  const { data: history, isLoading } = useQuery({
    queryKey: ["userSubs", id],
    queryFn: () => getUserWallet(id),
  });
  const user = useAuth((state) => state.user);
  console.log(history);

  const { data: userId } = useQuery({
    queryKey: ["user", id],
    queryFn: async () => await getUserById(id),
  });
  console.log(userId);

  return (
    <Access
      granted={
        userId?.role === "Talent"
          ? user?.viewTalentWallet
          : userId?.role === "Talent Seeker"
          ? user?.viewTalentSeekerWallet
          : user?.viewCompanyWallet
      }
    >
      <Card width={600} title="Wallet">
        <div
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 style={{ fontSize: 20 }}>Coins: {history?.wallet?.coins}</h1>

          {(userId?.role === "Talent"
            ? user?.addTalentWallet
            : userId?.role === "Talent Seeker"
            ? user?.addTalentSeekerWallet
            : user?.addCompanyWallet) && <AddtoWallet id={id} />}
        </div>
        <Table
          loading={isLoading}
          dataSource={history?.history}
          columns={[
            {
              title: "Title",
              dataIndex: "title",
            },
            {
              title: "Balance",
              dataIndex: "balance",
            },
            {
              title: "Date",
              dataIndex: "date",
            },
          ]}
        />
      </Card>
    </Access>
  );
};

export default Wallet;

const AddtoWallet = ({ id }) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const type = Form.useWatch("history", form);
  console.log(type);
  const queryClient = useQueryClient();
  const { isPending, mutate } = useMutation({
    mutationFn: async (values) => await addToWallet(id, values),
    onSuccess: () => {
      message.success("Coins added successfully");
      form.resetFields();
      setVisible(false);
      queryClient.invalidateQueries(["userSubs", id]);
    },
  });
  return (
    <>
      <Button onClick={() => setVisible(true)} type="primary">
        Add to Wallet
      </Button>
      <Modal
        open={visible}
        footer={null}
        width={600}
        onCancel={() => setVisible(false)}
        title="Wallet"
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          onFinish={(values) => {
            if (values.type) {
              mutate({
                coins: values.coins,
                message: values.message,

                type: values.type,
              });
            } else {
              mutate({
                coins: values.coins,
              });
            }
          }}
        >
          <Form.Item
            label="Coins"
            name="coins"
            rules={[
              {
                required: true,
                message: "Please enter coins",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="History" name="history">
            <Switch
              checkedChildren="Add To History"
              unCheckedChildren="Remove From History"
            />
          </Form.Item>
          {type && (
            <>
              <Form.Item label="Type" name="type">
                {/* Subtract  or Add */}

                <Select style={{ width: "100%" }}>
                  <Select.Option value="Add">Add</Select.Option>
                  <Select.Option value="Subtract">Subtract</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Message" name="message">
                <Input.TextArea />
              </Form.Item>
            </>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
