import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Form, Input, Typography, Upload, message } from "antd";

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addCategory, getCategoryById } from "../../utils/api";
import { UploadOutlined } from "@ant-design/icons";
import CategoriesCreateData from "../../components/categories";
import Access from "../../components/Access";
import { useAuth } from "../../utils/store";

const AddCategory = () => {
  const navigate = useNavigate();
  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await addCategory(values),
    onSuccess: () => {
      message.success(" created successfully");
      navigate("/categories");
    },
  });
  const params = useParams();
  const user = useAuth((state) => state.user);
  return (
    <Access granted={user?.addCategory}>
      <CategoriesCreateData mutate={mutate} isPending={isPending} />
    </Access>
  );
};

export default AddCategory;
