import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Card, Modal, Select, Table, message } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  addUserToSubscription,
  getAllsub,
  getSubscriptionHistory,
} from "../../utils/api";

const SubscriptionHistory = () => {
  const id = useParams().id;
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["subscriptionHistory", id],
    queryFn: async () => await getSubscriptionHistory(id),
  });

  console.log(data);
  const { data: subscriptions } = useQuery({
    queryKey: ["subscription"],
    queryFn: () => getAllsub(),
  });
  return (
    <>
      <Card
        loading={isLoading}
        title="Subscription History"
        extra={
          <AddSubscription
            id={id}
            refetch={() => refetch()}
            subs={subscriptions}
          />
        }
      >
        <Table
          dataSource={data?.[0]?.history}
          columns={[
            {
              title: "History",
              render: (record) => <p>{record}</p>,
            },
          ]}
        />
      </Card>
    </>
  );
};

export default SubscriptionHistory;

const AddSubscription = ({ subs, id, refetch }) => {
  const [visible, setVisible] = useState(false);
  const [selectedSub, setSelectedSub] = useState(null);

  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await addUserToSubscription(values),
    onSuccess: () => {
      message.success("Subscription added successfully");
      setSelectedSub(null);
      refetch();
      setVisible(false);
    },
  });
  return (
    <>
      <Button
        style={{
          width: "100%",
        }}
        onClick={() => setVisible(true)}
        type="primary"
      >
        Add Subscription
      </Button>
      <Modal
        confirmLoading={isPending}
        open={visible}
        onCancel={() => setVisible(false)}
        onOk={() => {
          if (selectedSub) {
            mutate({ subscriptionId: selectedSub, userId: id });
          }
        }}
        title="Add Subscription"
      >
        <Select
          options={subs?.map((sub) => ({ label: sub.title, value: sub._id }))}
          style={{
            width: "100%",
          }}
          onChange={(value) => setSelectedSub(value)}
          value={selectedSub}
        />
      </Modal>
    </>
  );
};
