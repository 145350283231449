import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";

import {
  Button,
  Image,
  Popconfirm,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { Link, useParams } from "react-router-dom";
import {
  deleteCategories,
  deleteSubCategories,
  getAllSubCategoriesByCategory,
} from "../../../utils/api";
import { exportToExcel } from "react-json-to-excel";
import { useAuth } from "../../../utils/store";
import Access from "../../../components/Access";

const AllSubCategories = () => {
  const params = useParams();
  const user = useAuth((state) => state.user);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["subcategories", params.cat],
    queryFn: () => getAllSubCategoriesByCategory(params.cat),
    enabled: !!user?.viewSubcategory,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await deleteSubCategories(values),
    onSuccess: () => {
      message.success(" deleted successfully");
      refetch();
    },
  });
  console.log(data);
  return (
    <Access granted={user?.viewSubcategory}>
      <div>
        <Space
          size={"large"}
          direction="horizontal"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Typography.Title>Sub Categories</Typography.Title>
          <Space size="middle" direction="horizontal">
            {user?.downloadSubcategory && (
              <Button
                type="primary"
                onClick={() => {
                  exportToExcel(
                    data.map((data) => ({
                      id: data._id,
                      name: data.name,
                      image: data.image,
                      slug: data.slug,
                      category: data.category,
                    })),
                    `sub${new Date().getTime()}`
                  );
                }}
              >
                Download
              </Button>
            )}
            <Link to={`/subcategories/${params.cat}/add`}>
              <Button type="primary">Add </Button>
            </Link>
          </Space>
        </Space>
        <Table
          dataSource={data}
          loading={isLoading}
          columns={[
            {
              title: "Name",
              dataIndex: "name",
            },

            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              render: (text, record) => (
                <Space size="middle">
                  <Link
                    to={`/subcategories/${params.cat}/update/${record?._id}`}
                  >
                    Edit
                  </Link>
                  {user?.deleteSubcategory && (
                    <Popconfirm
                      title="Delete"
                      description="Are you sure to delete this ?"
                      onConfirm={() => {
                        mutate(record._id);
                      }}
                      onCancel={() => {
                        console.log("canceled");
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button danger>Delete</Button>
                    </Popconfirm>
                  )}
                </Space>
              ),
            },
          ]}
        />
      </div>
    </Access>
  );
};

export default AllSubCategories;
