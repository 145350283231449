import { Button, Form, Input, InputNumber, Typography, Upload } from "antd";

import React from "react";

import { UploadOutlined } from "@ant-design/icons";
const SubscriptionCreateData = ({ mutate, update, isPending }) => {
  console.log(update);
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (update) {
      form.setFieldsValue({
        title: update.title,
        description: update.description,
        price: update.price,

        validity: update.validity,
      });
    }
  }, [update, form]);

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      style={{
        maxWidth: 600,
        width: "100%",
        margin: "auto",
        marginTop: 50,
        border: "1px solid #ccc",
        padding: "20px",
        borderRadius: 10,
      }}
      onFinish={(values) => {
        mutate(values);
      }}
    >
      <Typography.Title level={3}>
        {update ? "Update Subscription" : "Add Subscription"}
      </Typography.Title>
      <Form.Item label="Title" name="title">
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Price" name="price">
        <InputNumber
          style={{
            width: "100%",
          }}
        />
      </Form.Item>

      <Form.Item label="Validity" name="validity">
        <Input />
      </Form.Item>

      <Button htmlType="submit" loading={isPending} type="primary">
        Submit
      </Button>
    </Form>
  );
};

export default SubscriptionCreateData;
