import { UploadOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { Button, Card, Form, Input, message, Typography, Upload } from "antd";
import React from "react";
import { addNotification } from "../utils/api";
import Access from "../components/Access";
import { useAuth } from "../utils/store";

const Notification = () => {
  const [form] = Form.useForm();
  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await addNotification(values),
    onSuccess: () => {
      message.success(" created successfully");
      form.resetFields();
    },
  });
  const user = useAuth((state) => state.user);
  return (
    <Access granted={user?.sendNotifications}>
      <Form
        form={form}
        style={{
          maxWidth: 600,
          width: "100%",
          margin: "auto",
          marginTop: 50,
          border: "1px solid #ccc",
          padding: "20px",
          borderRadius: 10,
        }}
        labelCol={{ span: 8 }}
        onFinish={(values) => {
          const formData = new FormData();

          formData.append("title", values.title);
          formData.append("description", values.description);

          if (values.image) {
            formData.append("image", values.image.file.originFileObj);
          }

          mutate(formData);
        }}
      >
        <Typography.Title level={3}>Add Notification</Typography.Title>
        <Form.Item label="Title" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Image"
          name="image"
          style={{
            width: "100%",
          }}
        >
          <Upload>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Button htmlType="submit" loading={isPending} type="primary">
          Submit
        </Button>
      </Form>
    </Access>
  );
};

export default Notification;
