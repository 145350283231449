import { Button, Image, Layout, Menu, theme } from "antd";
import {
  Ampersand,
  ArrowRightLeft,
  Building,
  CalendarCheck,
  ConciergeBell,
  HardDrive,
  LayoutDashboard,
  Megaphone,
  Network,
  Send,
  ShieldCheck,
  Store,
  User2Icon,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/store";
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children, path) {
  return {
    key,
    icon,
    children,
    label,
    path,
  };
}

const Layouts = ({ children }) => {
  const logout = useAuth((state) => state.logout);
  const [collapsed, setCollapsed] = useState(false);
  const user = useAuth((state) => state.user);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const location = useLocation();

  const sideLinks = [
    user?.dashboard ? ["Dashboard", <LayoutDashboard size={16} />, "/"] : null,
    user?.systemValues
      ? ["System Values", <HardDrive size={16} />, "/system-values"]
      : null,
    user?.viewCategory
      ? ["Category", <Network size={16} />, "/categories"]
      : null,
    user?.viewTalent ? ["Talent", <User2Icon size={16} />, "/users"] : null,
    user?.viewTalentSeeker
      ? ["Talent Seekers", <Store size={16} />, "/vendors"]
      : null,
    user?.viewCompany ? ["Company", <Building size={16} />, "/company"] : null,
    user?.viewTalentNotifications
      ? ["Notifications", <ConciergeBell size={16} />, "/notifications"]
      : null,
    user?.viewPosts ? ["Posts", <Send size={16} />, "/posts"] : null,
    // ["Subscription", <CalendarCheck size={16} />, "/subscription"],

    user?.viewTransactions
      ? ["Transactions", <ArrowRightLeft size={16} />, "/transactions"]
      : null,
    user?.viewAdvertisements
      ? ["Advertisement", <Megaphone size={16} />, "/advert"]
      : null,

    user?.viewAdmin ? ["Admins", <ShieldCheck size={16} />, "/admins"] : null,
    user?.viewCollaborations
      ? ["Collaboration", <Ampersand size={16} />, "/collaboration"]
      : null,
  ].filter((item) => item !== null);

  const items = sideLinks.map((item, index) => {
    return getItem(item[0], index.toString(), item[1], null, item[2]);
  });

  useEffect(() => {
    if (!user?.email) {
      navigate("/login");
    }
  }, [user, navigate, location]);
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        breakpoint="lg"
        collapsedWidth="0"
      >
        <div
          style={{
            padding: 16,
          }}
        >
          <Image
            src={"/logo.png"}
            width={80}
            style={{
              borderRadius: 8,
            }}
            preview={false}
          />
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          selectedKeys={
            items && items.find((item) => item.path === location.pathname)?.key
          }
        >
          {items.map((item) => (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              onClick={() => {
                navigate(item.path);
              }}
            >
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: "#001529",
          }}
        >
          {/* dark nav bar logout*/}

          <div
            style={{
              display: "flex",
              justifyContent: collapsed ? "space-between" : "flex-end",
              alignItems: "center",
              gap: 16,
            }}
          >
            {collapsed && (
              <Image
                src={"/logo.png"}
                width={80}
                style={{
                  borderRadius: 8,
                  paddingLeft: 16,
                }}
                preview={false}
              />
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 16,
                paddingRight: 16,
              }}
            >
              <a href="" target="_blank" rel="noreferrer">
                <Button type="primary">Website</Button>
              </a>
              <a
                href="https://console.firebase.google.com/project/tangent-website-479e6/analytics/app/web:YjU5ODBhNzUtNDA1OS00MTlmLTk1OTAtMjM4ZmE3YTY1YjMx/overview"
                target="_blank"
                rel="noreferrer"
              >
                <Button type="primary">Analytics</Button>
              </a>
              <Button type="primary" onClick={() => logout()}>
                Logout
              </Button>

              {/* <Button type="primary" onClick={() => navigate("/login")}>
              Logout
            </Button> */}
            </div>
          </div>
        </Header>
        <Content style={{}}>
          {/* <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item>
          </Breadcrumb> */}
          <div
            style={{
              minHeight: "90dvh",
              background: colorBgContainer,
              padding: 24,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            {children}
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          FilmyKart
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Layouts;
