import { useQuery } from "@tanstack/react-query";
import { Button, Space, Table, Typography } from "antd";
import React from "react";
import { getTransactions } from "../utils/api";
import { exportToExcel } from "react-json-to-excel";
import { useAuth } from "../utils/store";
import Access from "../components/Access";

const Transactions = () => {
  const user = useAuth((state) => state.user);
  const { data, isLoading } = useQuery({
    queryKey: ["transactions"],
    queryFn: getTransactions,
    enabled: !!user.viewTransactions,
  });
  console.log(data);

  return (
    <Access granted={user?.viewTransactions}>
      <Space
        size={"large"}
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Space
          size={"large"}
          direction="horizontal"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Typography.Title>Transactions</Typography.Title>

          {user?.downloadTransactions && (
            <Space size="middle" direction="horizontal">
              <Button
                type="primary"
                onClick={() => {
                  exportToExcel(
                    data.map((data) => {
                      delete data.__v;
                      data.purchaseByPhoneNumber =
                        data?.purchaseBy?.phoneNumber ?? "N/A";
                      data.purchaseBy = data?.purchaseBy?.name;
                      delete data.purchasedUser;
                      return data;
                    }),

                    `transactions${new Date().getTime()}`
                  );
                }}
              >
                Download
              </Button>{" "}
            </Space>
          )}
        </Space>
        <Table
          isLoading={isLoading}
          dataSource={data}
          columns={[
            {
              title: "Purchase By",
              dataIndex: ["purchaseBy", "name"],
            },
            {
              title: "Purchase Date",
              dataIndex: "purchaseDate",
              render: (record) => (
                <p>
                  {new Date(record).toLocaleDateString()}{" "}
                  {new Date(record).toLocaleTimeString()}
                </p>
              ),
            },
            {
              title: "Amount",
              dataIndex: "amount",
            },
            {
              title: "Type",
              dataIndex: "type",
            },
          ]}
        />
      </Space>
    </Access>
  );
};

export default Transactions;
