import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Form, Input, Typography, Upload, message } from "antd";

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addCategory,
  getCategoryById,
  getSubById,
  updateCategory,
  updateSubs,
} from "../../utils/api";
import { UploadOutlined } from "@ant-design/icons";
import CategoriesCreateData from "../../components/categories";
import SubscriptionCreateData from "../../components/subscription";

const UpdateSubscription = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await updateSubs(params.id, values),
    onSuccess: () => {
      message.success("Successfully");
      navigate("/subscription");
    },
  });
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["subscription", params.id],
    queryFn: async () => await getSubById(params.id),
  });
  console.log(data);
  return (
    <SubscriptionCreateData
      mutate={mutate}
      isPending={isPending}
      update={data}
    />
  );
};

export default UpdateSubscription;
