import { useQuery } from "@tanstack/react-query";
import { Card, Image, Table, Tag } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { getUserById, getUserNotifications } from "../../utils/api";
import { useAuth } from "../../utils/store";
import Access from "../../components/Access";

const UserNotifications = () => {
  const user = useAuth((state) => state.user);
  const id = useParams().id;
  const { data, isLoading } = useQuery({
    queryKey: ["notifications", id],
    queryFn: async () => await getUserNotifications(id),
    enabled:
      !!user?.viewTalentNotifications ||
      !!user?.viewTalentSeekerNotifications ||
      !!user?.viewCompanyNotifications,
  });

  console.log(data);
  const { data: userId } = useQuery({
    queryKey: ["user", id],
    queryFn: async () => await getUserById(id),
  });
  return (
    <Access
      granted={
        userId?.role === "Talent"
          ? user?.viewTalentNotifications
          : userId?.role === "Talent Seeker"
          ? user?.viewTalentSeekerNotifications
          : user?.viewCompanyNotifications
      }
    >
      <Card loading={isLoading} title="Notifications">
        <Table
          dataSource={data}
          columns={[
            {
              title: "Image",
              dataIndex: "image",
              render: (text) => <Image src={text} width={50} />,
            },
            {
              title: "Title",
              dataIndex: "title",
            },
            {
              title: "Date",
              dataIndex: "date",
            },
            {
              title: "Read",
              dataIndex: "read",
              render: (record) => (
                <Tag color={record ? "green" : "red"}>
                  {record ? "Read" : "Unread"}
                </Tag>
              ),
            },
          ]}
        />
      </Card>
    </Access>
  );
};

export default UserNotifications;
