import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { deleteSubs, getAllsub, getUsers } from "../../utils/api";
import {
  Button,
  Image,
  Popconfirm,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { exportToExcel } from "react-json-to-excel";

const Subscription = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["subscription"],
    queryFn: () => getAllsub(),
  });

  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await deleteSubs(values),
    onSuccess: () => {
      message.success(" deleted successfully");
      refetch();
    },
  });

  console.log(data, isLoading);
  return (
    <div>
      <Space
        size={"large"}
        direction="horizontal"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 20,
        }}
      >
        <Typography.Title level={3}>Subscriptions</Typography.Title>

        <Space size="middle" direction="horizontal">
          <Button
            type="primary"
            onClick={() => {
              exportToExcel(
                data.map((data) => {
                  delete data.__v;

                  return data;
                }),

                `subscription${new Date().getTime()}`
              );
            }}
          >
            Download
          </Button>{" "}
          <Link to="/subscription/add">
            <Button type="primary">Add </Button>
          </Link>
        </Space>
      </Space>
      <Table
        dataSource={data}
        loading={isLoading}
        columns={[
          {
            title: "Title",
            dataIndex: "title",
          },
          {
            title: "Description",
            dataIndex: "description",
          },
          {
            title: "Price",
            dataIndex: "price",
          },
          {
            title: "Validity",
            dataIndex: "validity",
          },
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (text, record) => (
              <Space size="middle">
                <Link to={`/subscription/update/${record?._id}`}>Edit</Link>

                <Popconfirm
                  title="Delete"
                  description="Are you sure to delete this ?"
                  onConfirm={() => {
                    mutate(record._id);
                  }}
                  onCancel={() => {
                    console.log("canceled");
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger>Delete</Button>
                </Popconfirm>
              </Space>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Subscription;
