import { useQuery } from "@tanstack/react-query";
import { Card, Table } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { getPurchaseLeader } from "../../utils/api";

const TalentSeekerPurchase = () => {
  const id = useParams().id;

  const { data, isLoading } = useQuery({
    queryKey: ["talentSeekerPurchase", id],
    queryFn: async () => await getPurchaseLeader(id),
  });

  console.log(data);

  return (
    <>
      <Card loading={isLoading} title="Talent Seeker Purchase">
        <Table
          dataSource={data}
          columns={[
            {
              title: "Purchase By",
              dataIndex: ["purchaseBy", "name"],
            },
            {
              title: "Purchase Date",
              dataIndex: "purchaseDate",
            },
            {
              title: "Amount",
              dataIndex: "amount",
            },
            {
              title: "Type",
              dataIndex: "type",
            },
          ]}
        />
      </Card>
    </>
  );
};

export default TalentSeekerPurchase;
