import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Typography,
  Upload,
} from "antd";

import React from "react";

import { UploadOutlined } from "@ant-design/icons";
const UserCreateData = ({ mutate, update, isPending, vendor }) => {
  console.log(update);
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (update) {
      form.setFieldsValue({
        name: update.name,
        email: update.email,
        phoneNumber: update.phoneNumber,
        dob: update.dob,
      });
    }
  }, [update, form]);

  return (
    <Form
      form={form}
      style={{
        maxWidth: 600,
        width: "100%",
        margin: "auto",
        marginTop: 50,
        border: "1px solid #ccc",
        padding: "20px",
        borderRadius: 10,
      }}
      labelCol={{ span: 8 }}
      onFinish={(values) => {
        if (!update) {
          const formData = new FormData();

          formData.append("name", values.name);
          formData.append("email", values.email);
          formData.append("phoneNumber", values.phoneNumber);
          formData.append("dob", values.dob);
          formData.append("isVendor", vendor ? true : false);

          if (values.image) {
            formData.append("image", values.image.file.originFileObj);
          }
          mutate(formData);
        } else {
          mutate(values);
        }
      }}
    >
      <Typography.Title level={3}>
        {update
          ? `Update ${vendor ? "Vendor" : "User"}`
          : `Add ${vendor ? "Vendor" : "User"}`}
      </Typography.Title>
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Email" name="email">
        <Input />
      </Form.Item>
      <Form.Item label="Phone Number" name="phoneNumber">
        <InputNumber
          style={{
            width: "100%",
          }}
        />
      </Form.Item>
      {!update && (
        <Form.Item label="DOB" name="dob">
          <Input type="date" />
        </Form.Item>
      )}
      {!update && (
        <Form.Item
          label="Image"
          name="image"
          style={{
            width: "100%",
          }}
        >
          <Upload>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
      )}
      <Button htmlType="submit" loading={isPending} type="primary">
        Submit
      </Button>
    </Form>
  );
};

export default UserCreateData;
