import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Form, Input, Typography, Upload, message } from "antd";

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addCategory, getCategoryById, updateCategory } from "../../utils/api";
import { UploadOutlined } from "@ant-design/icons";
import CategoriesCreateData from "../../components/categories";
import Access from "../../components/Access";
import { useAuth } from "../../utils/store";

const UpdateCategory = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await updateCategory(params.id, values),
    onSuccess: () => {
      message.success("Successfully");
      navigate("/categories");
    },
  });
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["categories", params.id],
    queryFn: async () => await getCategoryById(params.id),
  });
  console.log(data);
  const user = useAuth((state) => state.user);
  return (
    <Access granted={user?.editCategory}>
      <CategoriesCreateData
        mutate={mutate}
        isPending={isPending}
        update={data}
      />
    </Access>
  );
};

export default UpdateCategory;
