import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Advert from "../../components/Advert";
import { addAdvert } from "../../utils/api";
import { useAuth } from "../../utils/store";
import Access from "../../components/Access";

const AddAdvert = () => {
  const navigate = useNavigate();
  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await addAdvert(values),
    onSuccess: () => {
      message.success(" created successfully");
      navigate("/advert");
    },
  });

  const params = useParams();
  const user = useAuth((state) => state.user);
  return (
    <Access granted={user?.addAdvertisements}>
      <Advert mutate={mutate} isPending={isPending} />
    </Access>
  );
};

export default AddAdvert;
