import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Image, Modal, Space, Table, Tag, Typography } from "antd";
import React, { useState } from "react";
import { allPosts, updatePostStatus, userPosts } from "../utils/api";
import { useParams } from "react-router-dom";
import { exportToExcel } from "react-json-to-excel";
import Access from "../components/Access";
import { useAuth } from "../utils/store";

const Posts = () => {
  const id = useParams().id;
  const [page, setPage] = useState(1);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["allPosts", page, id],
    queryFn: async () => (id ? await userPosts(id) : await allPosts(page)),
  });
  console.log(data);
  const user = useAuth((state) => state.user);
  return (
    <Access granted={user?.viewPosts}>
      <div>
        <Space
          size={"large"}
          direction="horizontal"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Typography.Title>Posts</Typography.Title>
          {user?.downloadPosts && (
            <Space size="middle" direction="horizontal">
              <Button
                type="primary"
                onClick={() => {
                  if (id) {
                    exportToExcel(
                      data.map((data) => {
                        delete data.__v;
                        data.username = data?.user?.name;
                        data.userPhoneNumber = data?.user?.phoneNumber ?? "N/A";
                        delete data.user;
                        return data;
                      }),

                      `posts${new Date().getTime()}`
                    );
                  } else {
                    exportToExcel(
                      data?.posts.map((data) => {
                        delete data.__v;
                        data.username = data?.user?.name;
                        data.userPhoneNumber = data?.user?.phoneNumber ?? "N/A";
                        delete data.user;
                        return data;
                      }),

                      `posts${new Date().getTime()}`
                    );
                  }
                }}
              >
                Download
              </Button>
            </Space>
          )}
        </Space>
        <Table
          dataSource={id ? data : data?.posts}
          loading={isLoading}
          scroll={{ x: 768 }}
          pagination={
            id
              ? {
                  total: data?.length,
                  onChange: (page) => {
                    setPage(page);
                  },
                  pageSize: 20,
                }
              : {
                  total: data?.totalData,
                  onChange: (page) => {
                    setPage(page);
                  },
                  pageSize: 20,
                }
          }
          columns={[
            {
              title: "Image",
              dataIndex: ["user", "image"],
              render: (image) => (
                <Image src={image} alt="user" width={100} height={100} />
              ),
            },
            {
              title: "Name",
              dataIndex: ["user", "name"],
            },
            {
              title: "Phone Number",
              dataIndex: ["user", "phoneNumber"],
            },
            {
              title: "Is Approved",
              dataIndex: ["isApproved"],
              render: (isApproved) =>
                isApproved ? (
                  <Tag color="green">Approved</Tag>
                ) : (
                  <Tag color="red">Not Approved</Tag>
                ),
            },
            {
              title: "Upload Date",
              dataIndex: ["uploadDate"],
              render: (date) => new Date(date).toLocaleDateString(),
            },
            {
              title: "Post",
              dataIndex: ["link"],
              render: (link, record) =>
                // .mp3, .wav, .flac, .ogg
                record?.linkType === "Video" ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      const isAudio =
                        link?.includes("mp3") ||
                        link?.includes("wav") ||
                        link?.includes("flac") ||
                        link?.includes("ogg");
                      Modal.info({
                        title: "Player",
                        width: 800,

                        content: isAudio ? (
                          <audio
                            controls
                            style={{
                              width: "100%",
                              maxHeight: "60dvh",
                            }}
                          >
                            <source src={link} type="audio/mpeg" />
                          </audio>
                        ) : (
                          <video
                            controls
                            style={{
                              width: "100%",
                              maxHeight: "60dvh",
                            }}
                          >
                            <source src={link} type="video/mp4" />
                          </video>
                        ),
                      });
                    }}
                  >
                    {link?.includes("mp3") ||
                    link?.includes("wav") ||
                    link?.includes("flac") ||
                    link?.includes("ogg")
                      ? "Play Audio"
                      : "Watch Video"}
                  </Button>
                ) : (
                  <Image src={link} alt="player" width={100} height={100} />
                ),
            },
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              render: (text, record) =>
                !record?.isApproved && (
                  <Space size="middle" direction="vertical">
                    {user?.acceptPosts && (
                      <ApproveDisapprove
                        id={record._id}
                        isApproved={true}
                        refetch={refetch}
                      />
                    )}
                    {user?.rejectPosts && (
                      <ApproveDisapprove
                        id={record._id}
                        isApproved={false}
                        refetch={refetch}
                      />
                    )}
                  </Space>
                ),
            },
          ]}
        />
      </div>
    </Access>
  );
};

export default Posts;

const ApproveDisapprove = ({ id, isApproved, refetch }) => {
  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      const { data } = await updatePostStatus(id, {
        isApproved,
        postStatus: isApproved ? "Approved" : "Rejected",
      });
      return data;
    },
    onSuccess: () => {
      refetch();
    },
  });
  return (
    <Button
      loading={isPending}
      style={{
        width: "100%",
      }}
      onClick={() => {
        mutate();
      }}
      danger={!isApproved}
    >
      {isApproved ? "Approve" : "Reject"}
    </Button>
  );
};
