import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Image,
  message,
  Popconfirm,
  Space,
  Table,
  Typography,
} from "antd";
import React from "react";

import Access from "../components/Access";
import { getUsers, updateUser } from "../utils/api";
import { useAuth } from "../utils/store";

const KYC = () => {
  const user = useAuth((state) => state.user);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["allUsers"],
    queryFn: () => getUsers("All"),
    enabled: !!user?.viewTalentSeeker,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: async (id) =>
      await updateUser(id, {
        isVerified: true,
      }),
    onSuccess: () => {
      message.success("Verified successfully");
      refetch();
    },
  });

  return (
    <Access granted={!!user?.kyc}>
      <div>
        <Space
          size={"large"}
          direction="horizontal"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Typography.Title>KYC</Typography.Title>
        </Space>

        <Table
          scroll={{ x: 800 }}
          dataSource={data?.filter(
            (data) => data?.isUploaded && !data?.isVerified
          )}
          loading={isLoading}
          columns={[
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              render: (text, record) => (
                <Space size="middle" direction="vertical">
                  <Popconfirm
                    title="Verify KYC"
                    description="Are you sure to verify this ?"
                    onConfirm={() => {
                      mutate(record._id);
                    }}
                    onCancel={() => {
                      console.log("canceled");
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{
                        width: "100%",
                      }}
                      type="primary"
                    >
                      Verify
                    </Button>
                  </Popconfirm>
                </Space>
              ),
            },
            {
              title: "User Name",
              dataIndex: "userName",
            },
            {
              title: "Image",
              dataIndex: "image",
              render: (text, record) => (
                <Image
                  src={text}
                  alt="blog"
                  style={{
                    width: 100,
                    height: 100,
                    objectFit: "contain",
                    backgroundColor: "#f0f0f0",
                  }}
                />
              ),
            },

            {
              title: "Name",
              dataIndex: "name",
            },
            {
              title: "Email",
              dataIndex: "email",
            },

            {
              title: "Phone Number",
              dataIndex: "phoneNumber",
            },
          ]}
        />
      </div>
    </Access>
  );
};

export default KYC;
