import { useMutation } from "@tanstack/react-query";
import { message } from "antd";

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addSubCategory } from "../../../utils/api";

import SubCategoriesCreateData from "../../../components/subcategories";
import Access from "../../../components/Access";
import { useAuth } from "../../../utils/store";

const AddSubCategory = () => {
  const navigate = useNavigate();

  const params = useParams();
  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await addSubCategory(values),
    onSuccess: () => {
      message.success(" created successfully");
      navigate(`/subcategories/${params.cat}`);
    },
  });
  const user = useAuth((state) => state.user);
  return (
    <Access granted={user.addSubcategory}>
      <SubCategoriesCreateData mutate={mutate} isPending={isPending} />
    </Access>
  );
};

export default AddSubCategory;
