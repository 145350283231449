import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Card,
  Image,
  InputNumber,
  Modal,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Access from "../../components/Access";
import { getLeaderboard, updatePostStatus } from "../../utils/api";
import { useAuth } from "../../utils/store";

const priceKeys = [
  "firstPrice",
  "secondPrice",
  "thirdPrice",

  "fourthPrice",
  "fifthPrice",
  "sixthPrice",
  "seventhPrice",
  "eighthPrice",
  "ninthPrice",
  "tenthPrice",
  "restPrice",
];

const Leaderboard = () => {
  const id = useParams().id;

  const user = useAuth((state) => state.user);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["leaderboard", id],
    queryFn: () => getLeaderboard(id),
    enabled: !!user?.viewLeaderboard,
  });
  return (
    <Access granted={user?.viewLeaderboard}>
      <Card
        style={{
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Typography.Title
            level={5}
            style={{
              margin: 0,
            }}
          >
            Total Money
          </Typography.Title>
          <Typography.Title
            level={4}
            style={{
              margin: 0,
            }}
          >
            {data?.leaderboard?.priceMoney}
          </Typography.Title>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title
            level={5}
            style={{
              margin: 0,
            }}
          >
            Last Submission Date
          </Typography.Title>
          <Typography.Title
            level={4}
            style={{
              margin: 0,
            }}
          >
            {new Date(data?.ad?.lastSubmissionDate).toLocaleDateString("en-US")}
          </Typography.Title>
        </div>
      </Card>
      <Card title="Leaderboard" loading={isLoading}>
        <Table
          dataSource={data?.posts}
          columns={[
            {
              title: "Rank",
              dataIndex: "ad",
              render: (text, _, index) => index + 1,
            },

            {
              title: "Username",
              dataIndex: ["user", "userName"],
            },
            {
              title: "Watch Time",
              dataIndex: ["watchTime"],
              render: (text, record) => (
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    alignItems: "center",
                  }}
                >
                  {text}
                </div>
              ),
            },
            {
              title: "Post",
              dataIndex: ["link"],
              render: (link, record) => (
                <div
                  key={record._id}
                  style={{
                    display: "flex",
                    gap: 10,

                    flexDirection: "column",
                  }}
                >
                  {record?.posts?.map((item, index) => (
                    <div
                      key={item._id}
                      style={{
                        display: "flex",
                        gap: 10,
                      }}
                    >
                      {item?.linkType === "Video" ? (
                        <Button
                          key={item._id}
                          type="primary"
                          onClick={() => {
                            Modal.info({
                              title: "Player",
                              width: 800,
                              content: (
                                <video
                                  controls
                                  style={{
                                    width: "100%",
                                    maxHeight: "60dvh",
                                  }}
                                >
                                  <source src={item.link} type="video/mp4" />
                                </video>
                              ),
                            });
                          }}
                        >
                          Watch Video {index + 1}
                        </Button>
                      ) : (
                        <Image
                          key={item._id}
                          src={item.link}
                          alt="player"
                          width={100}
                          height={100}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ),
            },
            {
              title: "Post Wise Watchtime",
              dataIndex: ["link"],
              render: (link, record) => (
                <div
                  key={record._id}
                  style={{
                    display: "flex",
                    gap: 10,

                    flexDirection: "column",
                  }}
                >
                  {record?.posts?.map((item) => (
                    <div
                      key={item._id}
                      style={{
                        display: "flex",
                        gap: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: 10,
                          alignItems: "center",
                        }}
                      >
                        {item?.watchTime}
                        <EditWatchTime
                          refetch={refetch}
                          id={item?._id}
                          current={item?.watchTime}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ),
            },
            {
              title: "Price Money",
              dataIndex: "ad",
              render: (text, _, index) => {
                const i = index + 1;
                const indexs = data?.leaderboard?.priceList?.map((item) => {
                  return +Object.keys(item)[0];
                });

                console.log(indexs);
                const indexGreater = indexs?.find((item) => item > i);
                const price = indexs?.find((item) => item === i)
                  ? data?.leaderboard?.priceList?.find(
                      (item) => Object.keys(item)[0] === i?.toString()
                    )?.[i]
                  : data?.leaderboard?.priceList?.find(
                      (item) =>
                        Object.keys(item)[0] === indexGreater?.toString()
                    )?.[indexGreater];

                console.log(price);

                return price;
              },
            },
          ]}
        />
      </Card>
    </Access>
  );
};
export default Leaderboard;
const EditWatchTime = ({ id, current, refetch }) => {
  const [watchTime, setWatchTime] = useState(current);
  const [open, setOpen] = React.useState(false);
  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      const { data } = await updatePostStatus(id, {
        watchTime,
      });
      return data;
    },
    onSuccess: () => {
      refetch();
      setOpen(false);
    },
  });

  useEffect(() => {
    setWatchTime(current);
  }, [current]);

  return (
    <>
      <Button
        loading={isPending}
        onClick={() => {
          setOpen(true);
        }}
      >
        Edit
      </Button>
      <Modal
        title="Edit Watch Time"
        confirmLoading={isPending}
        open={open}
        onOk={() => mutate()}
        onCancel={() => setOpen(false)}
      >
        <InputNumber
          style={{ width: "100%" }}
          value={watchTime}
          onChange={(e) => setWatchTime(e)}
        />
      </Modal>
    </>
  );
};
