import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Card, Form, InputNumber, Modal, message } from "antd";
import React, { useEffect } from "react";
import { dashboard, updateSystemValues } from "../utils/api";
import Access from "../components/Access";
import { useAuth } from "../utils/store";

const SystemValues = () => {
  const [systemValues, setSystemValues] = React.useState({});
  const [form] = Form.useForm();
  const { data, isLoading } = useQuery({
    queryKey: ["dashboard"],
    queryFn: dashboard,
  });
  const user = useAuth((state) => state.user);
  const { mutate, isPending } = useMutation({
    mutationFn: updateSystemValues,
    onSuccess: (data) => {
      message.success("System values updated successfully");
    },
  });

  console.log(data);
  useEffect(() => {
    setSystemValues(data?.systemValues);
    form.setFieldsValue(data?.systemValues);
  }, [data, form]);
  return (
    <Access granted={user?.systemValues}>
      <div>
        <Card
          title="System Values"
          style={{
            maxWidth: "500px",
            margin: "auto",
          }}
        >
          {" "}
          <Form
            title="System Values"
            form={form}
            layout="vertical"
            onFinish={(values) => {
              setSystemValues(values);
              mutate(values);
            }}
            initialValues={systemValues}
          >
            <Form.Item label="Post Count" name="postCount">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="User Purchase Amount" name="userAmount">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isPending || isLoading}
              style={{ width: "100%" }}
            >
              Update
            </Button>
          </Form>
        </Card>
      </div>
    </Access>
  );
};

export default SystemValues;
