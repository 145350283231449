import { useQuery } from "@tanstack/react-query";
import { Button, Card, Image, Modal, Table } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { getCollaboration, getLeaderboard } from "../../utils/api";

const priceKeys = [
  "firstPrice",
  "secondPrice",
  "thirdPrice",

  "fourthPrice",
  "fifthPrice",
  "sixthPrice",
  "seventhPrice",
  "eighthPrice",
  "ninthPrice",
  "tenthPrice",
  "restPrice",
];

const CollaborationAdvert = () => {
  const id = useParams().id;

  const { data, isLoading } = useQuery({
    queryKey: ["collaboration", id],
    queryFn: () => getCollaboration(id),
  });
  console.log(data);
  return (
    <Card title="Collaboration" loading={isLoading}>
      <Table
        dataSource={data}
        columns={[
          {
            title: "Image",
            dataIndex: ["user", "image"],
            render: (text) => <Image src={text} width={50} />,
          },
          {
            title: "Name",
            dataIndex: ["user", "name"],
          },

          {
            title: "Phone Number",
            dataIndex: ["user", "phoneNumber"],
          },
          {
            title: "Post",
            dataIndex: ["link"],
            render: (link, record) =>
              record?.linkType === "Video" ? (
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.info({
                      title: "Player",
                      width: 800,
                      content: (
                        <video
                          controls
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <source src={link} type="video/mp4" />
                        </video>
                      ),
                    });
                  }}
                >
                  Watch Video
                </Button>
              ) : (
                <Image src={link} alt="player" width={100} height={100} />
              ),
          },
        ]}
      />
    </Card>
  );
};
export default CollaborationAdvert;
