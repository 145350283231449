import { Card } from "antd";
import React from "react";

const Access = ({ granted, children }) => {
  return (
    <div>
      {granted ? (
        children
      ) : (
        <Card title="Access Denied">Please contact your administrator</Card>
      )}
    </div>
  );
};

export default Access;
