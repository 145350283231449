import { OrderedListOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Card, Col, Row, Space, Statistic, Typography } from "antd";

import { Building, Megaphone, Send, User2Icon } from "lucide-react";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { dashboard } from "../utils/api";
import { useAuth } from "../utils/store";
import { Link } from "react-router-dom";
import Access from "../components/Access";

const Dashboard = () => {
  const user = useAuth((state) => state.user);

  const { data: dash } = useQuery({
    queryKey: ["dashboard"],
    queryFn: dashboard,
    enabled: !!user?.dashboard,
  });

  console.log(user);

  return (
    <Access granted={user?.dashboard}>
      <Space size={"large"} direction="vertical">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Title>Dashboard</Typography.Title>
        </div>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            {" "}
            <Link
              to="/categories"
              style={{
                width: "100%",
              }}
            >
              <Card title="Total Categories">
                <Statistic
                  value={dash?.categories}
                  prefix={<OrderedListOutlined />}
                />
              </Card>
            </Link>
          </Col>{" "}
          <Col span={12}>
            <Link
              to="/categories"
              style={{
                width: "100%",
              }}
            >
              <Card title="Total Subcategories">
                <Statistic
                  value={dash?.subcategories}
                  prefix={<OrderedListOutlined />}
                />
              </Card>
            </Link>
          </Col>
          <Col span={12}>
            <Link to="/users" style={{ width: "100%" }}>
              <Card title="Total Talent">
                <Statistic value={dash?.talent} prefix={<User2Icon />} />
              </Card>
            </Link>
          </Col>
          <Col span={12}>
            <Link to="/vendors" style={{ width: "100%" }}>
              <Card title="Total Talent Seeker">
                <Statistic value={dash?.talentSeeker} prefix={<User2Icon />} />
              </Card>
            </Link>
          </Col>
          <Col span={12}>
            <Link to="/advert" style={{ width: "100%" }}>
              <Card title="Ads">
                <Statistic value={dash?.ads} prefix={<Megaphone />} />
              </Card>
            </Link>
          </Col>
          <Col span={12}>
            <Link to="/posts" style={{ width: "100%" }}>
              <Card title="Posts">
                <Statistic value={dash?.posts} prefix={<Send />} />
              </Card>
            </Link>
          </Col>
          <Col span={12}>
            <Link to="/company" style={{ width: "100%" }}>
              <Card title="Company">
                <Statistic value={dash?.companies} prefix={<Building />} />
              </Card>
            </Link>
          </Col>
        </Row>
        <Space
          direction="vertical"
          style={{
            width: "100%",
            padding: 20,
            backgroundColor: "white",
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
            User Graph
          </Typography>
          <BarChart
            width={
              window.innerWidth > 1000
                ? window.innerWidth - 400
                : window.innerWidth - 100
            }
            height={250}
            data={
              dash?.userGraph?.map((item) => {
                return {
                  date: `${new Date(
                    item?.dateStart
                  ).toLocaleDateString()} - ${new Date(
                    item?.dateEnd
                  ).toLocaleDateString()}`,
                  count: item?.count,
                  dateStart: item?.dateStart,
                  dateEnd: item?.dateEnd,
                  users: item?.users,
                };
              }) || []
            }
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis
              label={{
                value: "Number of Users",
                angle: -90,
                position: "insideLeft",
                offset: 10,
              }}
            />
            <Bar dataKey="count" fill="#8884d8" />
            <Legend />
            <Tooltip />
          </BarChart>
        </Space>
      </Space>
    </Access>
  );
};

export default Dashboard;
