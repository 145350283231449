import { Button, Form, Input, Typography } from "antd";

import React from "react";

import { useParams } from "react-router-dom";
const SubCategoriesCreateData = ({ mutate, update, isPending }) => {
  console.log(update);
  const [form] = Form.useForm();
  const params = useParams();
  React.useEffect(() => {
    if (update) {
      form.setFieldsValue({
        name: update.name,
      });
    }
  }, [update, form]);

  return (
    <Form
      form={form}
      style={{
        maxWidth: 600,
        width: "100%",
        margin: "auto",
        marginTop: 50,
        border: "1px solid #ccc",
        padding: "20px",
        borderRadius: 10,
      }}
      onFinish={(values) => {
        const formData = new FormData();

        formData.append("name", values?.name?.trim());

        if (params.cat) {
          formData.append("category", params.cat);
        }

        mutate(formData);
      }}
    >
      <Typography.Title level={3}>
        {update ? "Update Sub Category" : "Add Sub Category"}
      </Typography.Title>
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>

      <Button htmlType="submit" loading={isPending} type="primary">
        Submit
      </Button>
    </Form>
  );
};

export default SubCategoriesCreateData;
