import { useMutation, useQuery } from "@tanstack/react-query";
import { message } from "antd";

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SubCategoriesCreateData from "../../../components/subcategories";
import {
  getCategoryById,
  getSubCategoryById,
  updateSubCategory,
} from "../../../utils/api";
import Access from "../../../components/Access";
import { useAuth } from "../../../utils/store";

const UpdateSubCategory = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { mutate, isPending } = useMutation({
    mutationFn: async (values) => await updateSubCategory(params.id, values),
    onSuccess: () => {
      message.success("Successfully");
      navigate(`/subcategories/${params.cat}`);
    },
  });
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["sub-categories", params.id],
    queryFn: async () => await getSubCategoryById(params.id),
  });
  console.log(data);
  const user = useAuth((state) => state.user);
  return (
    <Access granted={user.editSubcategory}>
      <SubCategoriesCreateData
        mutate={mutate}
        isPending={isPending}
        update={data}
      />
    </Access>
  );
};

export default UpdateSubCategory;
