import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export const useAuth = create()(
  devtools(
    persist(
      (set) => ({
        user: null,

        setUser: (user) => set({ user }),
        logout: () => set({ user: null }),
      }),
      {
        name: "filmy-auth",
        getStorage: () => localStorage,
      }
    )
  )
);
